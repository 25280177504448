import React from "react";

const ThienAn = () => {
  return (
    <div className="produce-list">
      <div className="produce">
        <img src="./images/production/thien-an-12.jpg" alt="thienan" />
        <h4>Thiên An vỏ nhiều màu</h4>
        <p>Trọng lượng khí: 12kg</p>
        <p>Trọng lượng vỏ: ~12kg</p>
        <br />
        <p>- Sản phẩm gas dân dụng thường thấy</p>
        <p>- Sử dụng van ngang vặn truyền thống</p>
      </div>
      <div className="produce">
        <img
          src="./images/production/thien-an-cong-nghiep.jpg"
          alt="thienan"
        />
        <h4>Thiên An công nghiệp</h4>
        <p>Trọng lượng khí: 45kg</p>
        <p>Trọng lượng vỏ: ~35kg</p>
        <br />
        <p>- Phù hợp với xí nghiệp, công ty, nhà máy, quán cơm</p>
        <p>- Phù hợp với nhu cầu sử dụng lớn</p>
      </div>
    </div>
  );
};

export default ThienAn;
