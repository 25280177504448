import React from "react";

import "./TrangChu.css";
import GioiThieu from "./GioiThieu";
import GiayTo from "./GiayTo";
import KhachHang from "./KhachHang";

const TrangChu = () => {
  return (
    <>
      <GioiThieu />
      <br />
      <GiayTo />
      <br />
      <KhachHang />
    </>
  );
};

export default TrangChu;
