import React from "react";
import { Link, Route } from "react-router-dom";

import "./TaiLieu.css";
import CachDatBinhGas from "./CachDatBinhGas";
import ThaoLapBinhGas from "./ThaoLapBinhGas";
import UngDungCuaGas from "./UngDungCuaGas";
import XuLyRoRiGas from "./XuLyRoRiGas";
import YeuCauAnToan from "./YeuCauAnToan";

const TaiLieu = (props) => {
  const list = [
    ["cachdatbinhgas", "Cách đặt bình gas", CachDatBinhGas],
    ["thaolapbinhgas", "Cách tháo lắp bình gas an toàn", ThaoLapBinhGas],
    ["ungdungcuagas", "Ứng dụng của gas trong đời sống", UngDungCuaGas],
    ["xulyrorigas", "Xử Lý Rò Rỉ Gas", XuLyRoRiGas],
    ["yeucauantoan", "Yêu cầu an toàn đối với khí hoá lỏng", YeuCauAnToan],
  ];

  return (
    <>
      <ul id="list-tai-lieu">
        {list.map((item) => (
          <Link to={"/tailieu/" + item[0]} key={item[0]}>
            <li>{item[1]}</li>
          </Link>
        ))}
      </ul>
      {props.location.pathname === "/tailieu" ? (
        <div id="document" className="center-mid">
          <h1>View</h1>
          <img src="/images/icon/icons8_document.ico" alt="document" />
        </div>
      ) : null}
      {list.map((item) => (
        <Route path={"/tailieu/" + item[0]} component={item[2]} />
      ))}
    </>
  );
};

export default TaiLieu;
