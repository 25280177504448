import React from "react";

const GioiThieu = () => {
  return (
    <div>
      <div className="center-row">
        <img src="/images/icon/icons8_dot.ico" alt="down" />
        <h2 className="title">Về chúng tôi</h2>
      </div>
      <hr />
      <h1 className="text-center text-orangered">
        CÔNG TY TNHH MỘT THÀNH VIÊN <br />
        TRƯỜNG AN PHÁT
      </h1>
      <br />
      <p>
        <b>
          <i>Công ty TNHH Một Thành Viên Trường An Phát – Chi nhánh Hà Nội </i>
        </b>
        được thành lập từ ngày 20 tháng 07 năm 2016 theo giấy phép số:
        0800445449-001 cấp bởi Phòng đăng ký Kinh doanh - Sở Kế hoạch và Đầu tư
        thành phố Hà Nội.
      </p>
      <br />
      <p>
        <b>
          <i> Công ty TNHH Một Thành Viên Trường An Phát </i>
        </b>
        chuyên phân phối và quản lý Gas hóa lỏng (LPG) dân dụng, thương mại và
        công nghiệp. Công ty cung cấp các loại gas bình (bình 11kg, 12kg, 45kg),
        gas bồn, dịch vụ tư vấn, lắp đặt, bảo trì các hệ thống tồn trữ và phân
        phối gas hoá lỏng (LPG) cho mọi đối tượng khách hàng.
      </p>
      <br />
      <p>
        Với sứ mệnh đem đến cho đối tác và khách hàng những sản phẩm/dịch vụ an
        toàn, chất lượng, đạt tiêu chuẩn cao. Hiện nay, Trường An Phát không chỉ
        là đại lý chính thức của
        <b>
          <i> PETROVIETNAM GAS </i>
        </b>
        (thuộc Tập đoàn Dầu khí Quốc gia Việt Nam – PETROVIETNAM) mà còn là đại
        lý của các Hãng Gas như:
        <b>
          <i> TOTALGAZ </i>
        </b>
        (Pháp),
        <b>
          <i> PETROTHIENAN GAS</i>
        </b>
        ; là những nhãn hiệu gas hàng đầu trên thế giới và trong nước có mặt tại
        Việt Nam đạt tiêu chuẩn cao về chất lượng thương mại cũng như an toàn
        sản phẩm.
      </p>
      <br />
      <p>
        Bên cạnh đó, với đội ngũ cán bộ chuyên môn có trình độ cao và đội ngũ
        công nhân kỹ thuật lành nghề, tận tụy với công việc,
        <b>
          <i> Trường An Phát </i>
        </b>
        đã và đang được các đối tác tin tưởng, đánh giá cao trong lĩnh vực tư
        vấn, thiết kế, cung cấp và lắp đặt hệ thống LPG cho các Công trình Dân
        dụng, Thương mại và Công nghiệp với chi phí tối ưu trong lắp đặt và vận
        hành. Hệ thống máy móc và thiết bị được lắp đặt luôn là những thiết bị
        chuyên ngành Dầu – Khí của các thương hiệu nổi tiếng từ các nước Mỹ,
        Đức, Pháp, Nhật Bản, Italia, Agentina, Đan Mạch, Hàn Quốc với các thương
        hiệu như: CORKEN, CONPIPE, BLACKMER, REGO, COMAP, KOSAN CRISPLANT,
        ITOKOKY, WIKA, ENTEC, 3Z VALVE, MIYAIRY, KITZ, SRG, GROTH Corp, UT
        VALVES, HS VALVES, IDEX FLUID & METERING, DS METER, Koler, Bifa China,
        IVECO Italia …
      </p>
      <br />
      <p>
        <b>
          <i>Trường An Phát </i>
        </b>
        xin chân thành cảm ơn sự tin cậy và đánh giá cao của các công ty Nhật
        bản, Đài Loan, Hàn Quốc ở các khu công nghiệp, Trường quốc tế, Các nhà
        hàng cao cấp nổi tiếng, Các nhà máy sản xuất đệm bông, bật lửa, HT các
        công ty chuyên nấu ăn cho các bếp ăn tập thể ở các khu công nghiệp,
        trường học, Hệ thống các đại lý bán lẻ dân dụng ở Miền Bắc tại các tỉnh
        từ Hà nội đến Lào cai, Hà Giang, Lạng sơn vào đến Nghệ An, ….. cùng
        nhiều Khách hàng khác đã và đang sử dụng Sản phẩm và Dịch vụ do Công ty
        Trường An Phát cung cấp.
      </p>
      <br />
      <p>
        Bằng chất lượng sản phẩm & thiết bị, kinh nghiệm và kỹ thuật cao cùng
        với nỗ lực không ngừng đổi mới khả năng cung cấp và dịch vụ tối ưu,{" "}
        <b>
          <i>Công ty TNHH Một Thành Viên Trường An Phát – Chi nhánh Hà Nội </i>
        </b>
        mong muốn được đóng góp một phần công sức của mình vào sự thành công và
        phát triển của Quý Khách hàng.
      </p>
      <br />
    </div>
  );
};

export default GioiThieu;
