import React, { useState } from "react";

const GiayTo = () => {
  const [current, setCurrent] = useState(0);
  const [image, setImage] = useState(0);
  const data = [
    {
      name: "Hồ sơ pháp lí",
      images: ["chi-nhanh-TAP.png", "giay-thuong-nhan.png"],
    },
    {
      name: "Hệ thống kho gas",
      images: [
        "hop-dong-thue-xuong-kho-bai-1.png",
        "hop-dong-thue-xuong-kho-bai-2.png",
        "hop-dong-thue-xuong-kho-bai-3.png",
        "giay-chung-nhan-pccc.png",
      ],
    },
    {
      name: "Kiểm định gas",
      images: [
        "giay-kiem-dinh.png",
        "kiem-dinh-ky-thuat-an-toan.png",
        "kiem-dinh-ky-thuat-an-toan-2.png",
        "kiem-dinh-ky-thuat-an-toan-3.png",
        "phieu-kiem-dinh-ket-qua-1.png",
        "phieu-kiem-dinh-ket-qua-2.png",
      ],
    },
    {
      name: "Bảo hiểm gas",
      images: [
        "bao-hiem-trach-nhiem-san-pham.png",
        "bao-hiem-trach-nhiem-san-pham-2.png",
        "giay-chung-nhan-bao-hiem.png",
        "giay-chung-nhan-pccc-2.png",
      ],
    },
  ];

  return (
    <div>
      <div className="center-row">
        <img src="/images/icon/icons8_dot.ico" alt="down" />
        <h2 className="title">Được đăng kí đầy đủ chứng nhận</h2>
      </div>
      <hr />
      <div className="center-row space-evenly">
        <div
          className="col-2 center-mid control"
          onClick={() => {
            setCurrent(current < 1 ? data.length - 1 : current - 1);
            setImage(0);
          }}
        >
          <img src="/images/icon/icons8_chevron_left.ico" alt="control" />
        </div>
        <h1 className="col-10 text-center">{data[current].name}</h1>
        <div
          className="col-2 center-mid control"
          onClick={() => {
            setCurrent(current + 2 > data.length ? 0 : current + 1);
            setImage(0);
          }}
        >
          <img src="/images/icon/icons8_chevron_right.ico" alt="control" />
        </div>
      </div>
      <div className="center-row space-evenly">
        <div
          className="col-2 center-mid control-image"
          onClick={() =>
            setImage(image < 1 ? data[current].images.length - 1 : image - 1)
          }
        >
          <img src="/images/icon/icons8_back.ico" alt="control" />
        </div>
        <div className="col-10 center-col">
          <img
            className="col-12"
            src={"/images/about/" + data[current].images[image]}
            alt="giayto"
          />
        </div>
        <div
          className="col-2 center-mid control-image"
          onClick={() =>
            setImage(image + 2 > data[current].images.length ? 0 : image + 1)
          }
        >
          <img src="/images/icon/icons8_forward.ico" alt="control" />
        </div>
      </div>
      <br />
    </div>
  );
};

export default GiayTo;
