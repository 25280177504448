import React from "react";
import { Route } from "react-router-dom";

import "./Content.css";
import TrangChu from "./TrangChu";
import DichhVu from "./DichVu";
import SanPham from "./SanPham";
import TaiLieu from "./TaiLieu";

const Content = () => {
  return (
    <div id="content" class="center-col">
      <Route exact path="/" component={TrangChu} />
      <Route path="/dichvu" component={DichhVu} />
      <Route path="/sanpham" component={SanPham} />
      <Route path="/tailieu" component={TaiLieu} />
    </div>
  );
};

export default Content;
