import React from "react";

const PVGas = () => {
  return (
    <div className="produce-list">
      <div className="produce">
        <img src="./images/production/pvgas-cong-nghiep.jpg" alt="pvgas" />
        <h4>PVGas công nghiệp</h4>
        <p>Trọng lượng khí: 45kg</p>
        <p>Trọng lượng vỏ: ~35kg</p>
        <br />
        <p>- Phù hợp với xí nghiệp, công ty, nhà máy, quán cơm</p>
        <p>- Phù hợp với nhu cầu sử dụng lớn</p>
      </div>
    </div>
  );
};

export default PVGas;
