import React from "react";

const UngDungCuaGas = () => {
  return (
    <div id="ungdungcuagas">
      <h2>Ứng dụng của gas trong các lĩnh vực</h2>
      <br />
      <div className="l-10">
        <p>
          Trong sinh hoạt hằng ngày, nhắc đến gas, chúng ta thường nghĩ ngay đến
          các loại bình gas dân dụng dùng trong việc nấu ăn. Trong thực tế, khí
          hóa lỏng được ứng dụng đa dạng hơn rất nhiều. Ngoài nấu bếp, gas còn
          được sử dụng cho các loại lò nướng; thay thế điện trong các bình đun
          nước nóng sử dụng cho tắm rửa; các hệ thống sưởi ấm nhà ở, chiếu sáng…
          Tuy nhiên, những ứng dụng này chỉ phát triển rộng rãi tại các nước ôn
          đới. Còn ở Việt Nam, Totalgaz với mẫu mã đa dạng và dung tích lớn còn
          đáp ứng được nhu cầu trong các lãnh vực như:
        </p>
        <br />
        <ul className="l-25">
          <li>
            Nhà hàng - Khách sạn: Những bình gas công nghiệp được sử dụng cho
            các bếp công nghiệp, lò nướng lớn, bình đun nước nóng trung tâm để
            cung cấp nước cho cả hệ thống phức tạp, hệ thống giặt là với công
            suất lớn,...
          </li>
          <br />
          <li>
            Chế biến thực phẩm: Ngành công nghiệp chế biến thực phẩm cần sử dụng
            gas cho các sản phẩm thịt (heo/bò/cá) hun khói, thịt nướng, chế biến
            khoai tây,... hoặc sử dụng khí gas để sấy nông sản ngũ cốc, thuốc
            lá, chè, café, lò ấp trứng, đốt cỏ, sưởi ấm nhà kính.
          </li>
          <br />
          <li>
            Gia công kim loại: LGP hay còn gọi là khí gas hóa lỏng là một trong
            hai nhiên liệu chính được kết hợp với oxy để hàn/cắt kim loại. Ưu
            thế cho ứng dụng này là sự đơn giản, dễ vận hành, có thể cắt được
            kim loại có chiều dày lớn, có năng suất cao, được sử dụng rộng rãi
            trong ngành công nghiệp đóng tàu, chế tạo toa xe, cắt các thép tấm,
            phôi tròn và các dạng phôi khác trong lãnh vực xây dựng…
          </li>
          <br />
          <li>
            Thủ công gốm sứ: Việc nung gốm sứ bằng gas vào trong sản xuất gốm sứ
            là một bước tiến lớn góp phần giúp tiết kiệm tiêu hao nhiên liệu,
            giảm chi phí sản xuất và tiết kiệm thời gian nung. Bên cạnh đó, còn
            cho ra những sản phẩm có chất lượng cao hơn và giúp tăng doanh thu
            cho nhà sản xuất.
          </li>
          <br />
          <li>
            Bao bì và các chế phẩm nhựa: Có thể bạn chưa biết, nhưng hầu hết hạt
            nhựa được tinh luyện, sản xuất từ dầu thô và khí gas thiên nhiên,
            chỉ một số ít được sản xuất từ bắp hoặc một số các chế phẩm sinh học
            khác. Các loại bao bì và chế phẩm nhựa được làm từ các hạt nhựa này
            đang được sử dụng rộng rãi trong đời sống hằng ngày của chúng ta.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UngDungCuaGas;
