import React from "react";

import "./DichVu.css";
import NangLucThietBi from "./NangLucThietBi";
import LinhVucKinhDoanh from "./LinhVucKinhDoanh";
import Catalog from "./Catalog";

const DichVu = () => {
  return (
    <>
      <h3>I. LĨNH VỰC HOẠT ĐỘNG KINH DOANH</h3>
      <br />
      <LinhVucKinhDoanh />
      <br />
      <h3>II. NĂNG LỰC THIẾT BỊ</h3>
      <br />
      <NangLucThietBi />
      <br />
      <h3>III. SẢN PHẨM GAS</h3>
      <Catalog />
    </>
  );
};

export default DichVu;
