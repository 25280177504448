import React from "react";

const Total = () => {
  return (
    <div className="produce-list">
      <div className="produce">
        <img src="./images/production/total-elf-6.jpg" alt="total" />
        <h4>Total elf gas</h4>
        <p>Trọng lượng khí: 6kg</p>
        <p>Trọng lượng vỏ: ~9kg</p>
        <br />
        <p>- Phù hợp cho quán lẩu, nướng</p>
        <p>- Sử dụng tốt trong không gian khiêm tốn</p>
      </div>
      <div className="produce">
        <img src="./images/production/total-van-dung-12.jpg" alt="total" />
        <h4>Total van chụp</h4>
        <p>Trọng lượng khí: 12kg</p>
        <p>Trọng lượng vỏ: ~12kg</p>
        <br />
        <p>- Sản phẩm gas dân dụng thường thấy</p>
        <p>- Sử dụng van đứng thông minh, tháo lắp nhanh chóng tiện dụng</p>
      </div>
      <div className="produce">
        <img src="./images/production/total-van-ngang-xanh.jpg" alt="total" />
        <h4>Total van ngang vỏ xanh</h4>
        <p>Trọng lượng khí: 12kg</p>
        <p>Trọng lượng vỏ: ~12kg</p>
        <br />
        <p>- Sản phẩm gas dân dụng thường thấy</p>
        <p>- Sử dụng van ngang vặn truyền thống</p>
      </div>
      <div className="produce">
        <img src="./images/production/total-cong-nghiep-cam.jpg" alt="total" />
        <h4>Total công nghiệp vỏ cam</h4>
        <p>Trọng lượng khí: 45kg</p>
        <p>Trọng lượng vỏ: ~35kg</p>
        <br />
        <p>- Phù hợp với xí nghiệp, công ty, nhà máy, quán cơm</p>
        <p>- Phù hợp với nhu cầu sử dụng lớn</p>
      </div>
    </div>
  );
};

export default Total;
