import React from "react";

const KhachHang = () => {
  return (
    <div>
      <div className="center-row">
        <img src="/images/icon/icons8_dot.ico" alt="down" />
        <h2 className="title">Chúng tôi đã làm việc với nhiều khách hàng</h2>
      </div>
      <hr />
      <div className="center-col customer">
        <h4 className="col-12 text-center">
          Công ty cổ phần thương mại dịch vụ Cổng Vàng (Golden Gate)
        </h4>
        <img className="col-6" src="./images/customer/ggg.png" alt="img" />
        <img
          className="col-12"
          src="./images/customer/ggg-customer.png"
          alt="img"
        />
      </div>
      <div className="center-row customer">
        <h4 className="col-6 text-center">
          Hệ Thống Nhà Hàng Cầu Gỗ (Fu Rong Hua, Cau Go Restaurant, Avalon Café
          Lounge, Avalon BBQ Garden).
        </h4>
        <img className="col-6" src="./images/customer/cau-go.png" alt="img" />
      </div>
      <div className="center-row customer">
        <img
          className="col-6"
          src="./images/customer/british-university.png"
          alt="img"
        />
        <h4 className="col-6 text-center">
          Trường Anh Ngữ Quốc tế (British University Viet Nam)_Ecopark Hưng Y
        </h4>
      </div>
      <div className="center-row customer">
        <h4 className="col-6 text-center">
          Công ty TNHH Hà Nội Seowon Intech_KCN Yên Phong, Bắc Ninh
        </h4>
        <img className="col-6" src="./images/customer/seowon.png" alt="img" />
      </div>
      <div className="center-row customer">
        <img className="col-6" src="./images/customer/wonjin.png" alt="img" />
        <h4 className="col-6 text-center">
          TNHH WONJIN VINA_KCN Vân Trung, tỉnh Bắc Giang
        </h4>
      </div>
      <div className="center-col customer">
        <h4 className="text-center">
          Công ty cổ phần ống đồng Toàn phát_ KCN Phố Nối, tỉnh Hưng Yên
        </h4>
        <div>
          <img
            className="col-6"
            src="./images/customer/toan-phat-2.png"
            alt="img"
          />
          <img
            className="col-6"
            src="./images/customer/toan-phat-2-2.png"
            alt="img"
          />
        </div>
      </div>
      <br />
      <h1 className="text-center">
        Hãy để chúng tôi trở thành nhà cung cấp gas cho bạn!!!
      </h1>
    </div>
  );
};

export default KhachHang;
