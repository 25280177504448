import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./Navbar.css";

const Navbar = () => {
  const [menu, setMenu] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const navbar = document.getElementById("navbar");
      if (window.pageYOffset < 180) {
        setMenu(true);
        navbar.classList.add("navbar-default");
        navbar.classList.remove("navbar-min");
      } else {
        if (window.innerWidth > 768) setMenu(true);
        else setMenu(false);
        navbar.classList.add("navbar-min");
        navbar.classList.remove("navbar-default");
      }
    });
  });

  return (
    <div id="navbar" className="col-12 navbar-default">
      <Link to="/">
        <img src="/images/icon/TAP-full-icon.png" alt="home" />
      </Link>
      <img
        src="/images/icon/icons8_menu.ico"
        alt="menu"
        onClick={() => setMenu(!menu)}
      />
      <ul style={{ display: menu ? "block" : "none" }}>
        <Link to="/">
          <li>Trang chủ</li>
        </Link>
        <Link to="/sanpham">
          <li>Sản phẩm</li>
        </Link>
        <Link to="/dichvu">
          <li>Dịch vụ</li>
        </Link>
        <Link to="/tailieu">
          <li>Tài liệu</li>
        </Link>
      </ul>
    </div>
  );
};

export default Navbar;
