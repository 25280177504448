import React from "react";

const CachDatBinhGas = () => {
  return (
    <div id="cachdatbinhgas">
      <h2>Cách đặt bình gas đúng</h2>
      <br />
      <div className="l-10">
        <p>
          <strong>
            An toàn phòng tránh cháy nổ không phải là chuyện gì quá to tát, nó
            chỉ đơn giản như việc bạn chọn “phong thủy” cho bình gas đảm bảo
            đúng cách theo hướng dẫn dưới đây.
          </strong>
        </p>
        <br />
        <p>
          <strong>Vị trí của bình gas</strong>
        </p>
        <p className="l-20">
          Chúng ta thường ít lưu ý vấn đề tương quan giữa bình gas và bếp, nhưng
          đặt bình gas thấp hơn bếp giúp khí gas lưu thông tốt hơn. Bên cạnh đó,
          dù cho nhà chật hẹp thì bạn cũng không nên đặt bình gas ngay dưới bếp
          mà nên cách xa bếp cũng như các nguồn nhiệt, tia lửa điện như bếp,
          công tắc, phích cắm… tối thiểu 1,5m.
        </p>
        <br />
        <p>
          <strong>“Dáng đứng” của bình gas</strong>
        </p>
        <p className="l-20">
          Đôi khi vì bình gas quá cao nên ta thường chọn cách đặt nằm nghiêng để
          vừa tủ bếp thấp hơn so với kích thước nhưng điều này rất không nên.
          Bởi, nếu có hiện tượng rò rỉ, phần gas đổ ra ngoài sẽ trở thành gas
          lỏng, khi hóa hơi sẽ có rất nhiều khí gas trong không khí nên dễ gây
          cháy nổ. Hơn nữa khi đặt bình gas nằm ngang sẽ không bật được bếp do
          gas lỏng sẽ tràn lên ống, và thể tích của ống không đủ để gas hóa hơi.
          Thế nên, đầu đốt gas sẽ không có khí gas để bật được bếp
        </p>
        <br />
        <p className="l-20">
          Khi được đặt thẳng đứng, phần trên của bình là phần gas đã hóa hơi;
          thế nên, nếu có rò rỉ thì chỉ có gas dạng hơi thoát ra, do đó khí gas
          khi đó sẽ ít hơn.
        </p>
        <br />
        <p className="l-20">
          Nếu tủ bếp nhỏ, bạn nên đặt bình gas ở bên ngoài, nơi thoáng khí - vừa
          thuận tiện thay bình, vừa đề phòng cháy nổ.
        </p>
        <br />
        <p>
          <strong>Đặt bình gas vào tủ bếp</strong>
        </p>
        <p className="l-20">
          Một chiếc tủ bếp có thể đặt được bình gas vào bên trong không chỉ giúp
          tiết kiệm đáng kể không gian sử dụng, tạo thẩm mỹ cho khuôn viên bếp,
          mà còn giúp tạo khoảng cách an toàn đối với trẻ nhỏ. Đối với tủ bếp ở
          vị trí đặt bình gas, bạn cũng nên lưu ý chọn loại cửa tủ có nhiều kẽ
          hở để có thể ngửi được nếu khí gas bị rò rỉ. Đồng thời, phần tiếp đất
          của cửa tủ phải thật thông thoáng vì khi gas nặng nên khi rò rỉ bao
          giờ cũng tràn xuống dưới đất.
        </p>
        <br />
        <p>Chúc các bạn luôn là người sử dụng gas an toàn!</p>
      </div>
    </div>
  );
};

export default CachDatBinhGas;
