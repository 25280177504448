import React from "react";

import "./Footer.css";

const Footer = () => {
  return (
    <div id="footer" className="center-mid">
      <div className="col-10">
        <h4>Công Ty Trường An Phát</h4>
        <hr />
        <br />
        <div className="col-6">
          <p>- Tel: 0243 8800 666</p>
          <p>- Moblie: 0988 162 168</p>
          <p>- Email: Truonganphat.hn@gmail.com</p>
          <p>
            - Địa chỉ: Số 11 nhà N7 TTQĐ J112, P. Ngọc Thụy, Q. Long Biên, Tp.
            Hà Nội
          </p>
        </div>
        <div className="col-6">
          <p>
            Chuyên phân phối và quản lý Gas hóa lỏng (LPG) dân dụng, thương mại
            và công nghiệp.
          </p>
          <a
            className="center-row"
            href={
              "https://www.google.com/maps/place/21%C2%B008'44.9%22N+105%C2%B05" +
              "1'53.8%22E/@21.1460027,105.8653305,20.05z/data=!4m6!3m5!1s0x3135" +
              "01280547d25b:0x100bd78e36a361c5!7e2!8m2!3d21.1458013!4d105.864945"
            }
          >
            <img src="/images/icon/icons8_Google_Maps_Old.ico" alt="gmap" />
            <p>Maps</p>
          </a>
          <a className="center-row" href="http://facebook.com/">
            <img src="/images/icon/icons8_Facebook.ico" alt="facebook" />
            <p>Facebook</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
