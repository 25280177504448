import React from "react";

const ThaoLapBinhGas = () => {
  return (
    <div id="thaolapbinhgas">
      <h2>Cách tháo lắp bình gas an toàn</h2>
      <br />
      <div className="l-10">
        <p>
          Bếp gas là sản phẩm đã quá quen thuộc với mỗi gia đình. Tuy nhiên,
          nhiều người vẫn quan ngại khi sử dụng bếp gas bởi nó tiềm ẩn nguy cơ
          cháy nổ nếu không sử dụng và bảo quản đúng cách. Việc tháo lắp bình
          gas sao cho an toàn không phải ai cũng biết. Để đảm bảo an toàn cho
          người dùng,
        </p>
        <br />
        <br />
        <h3>Cách tháo lắp bình gas van ngang:</h3>
        <br />
        <h4 className="l-20">Tháo:</h4>
        <div className="l-30">
          <p>
            <strong>Bước 1:</strong> Khóa van bình gas: Phải vặn đóng khóa bình
            gas trước khi tháo van ra{" "}
            <span style={{ color: "#ff0000" }}>
              (phải thực hiện, nếu quên thì rất nguy hiểm vì gas trong bình sẽ
              xì ra)
            </span>
            . Sau đó Bạn thực hiện xoay núm trên van bình gas "chú ý: vặn núm
            xoay theo chiều kim đồng hồ" để đóng gas lại.
          </p>
          <p>
            <strong>Bước 2:</strong> Tháo van ra khỏi bình gas: Đối với loại van
            điều áp có ren vặn thì về nguyên tắc chung là dùng loại "ren trái",
            tức là vặn vào ngược chiều kim đồng hồ và mở ra theo chiều kim đồng
            hồ. Cách thực hiện như sau: tay trái cầm chặt van, tay phải vặn van
            ngược chiều kim đồng hồ.
          </p>
          <br />
        </div>
        <h4 className="l-20">Lắp:</h4>
        <div className="l-30">
          <p>
            Sau khi di chuyển bình gas đến vị trí mới hay sau khi đã dọn dẹp, vệ
            sinh khu vực để bình gas và bếp. Các Bạn hãy chú ý lắp lại bình gas
            theo những bước sau:
          </p>
          <p>
            <strong>Bước 1:</strong> Đặt bếp vào vị trí, dùng tuốc vít siết chặt
            dây gas vào ống dẫn gas của bếp (bằng kẹp dây gas chuyên dụng), và
            làm tương tự với đầu van - Nếu như bạn chỉ tháo van ra khỏi bình gas
            để di chuyển tạm thời thì không phải làm bước này.
          </p>
          <p>
            <strong>Bước 2:</strong> Lắp van vào bình gas: Thường thì quy trình
            lắp van gas vào bình sẽ ngược lại với quy trình tháo van ra khỏi
            bình gas - Tay trái cầm van, tay phải vặn van theo chiều kim đồng
            hồ.
          </p>
          <p>
            <strong>Cuối cùng:</strong> sau khi lắp gas xong, Bạn nên dùng bọt
            nước xà phòng để kiểm tra bình gas có bị rò rỉ gas hay không rồi mới
            sử dụng.
          </p>
        </div>
        <br />
        <p>
          <strong>♥♥♥ Lưu ý:</strong> Khi mở van bình gas Bạn chỉ nên mở 1/4
          vòng để khi khóa van chỉ cần vặn ngược lại 1/4 vòng. Bạn cần tránh
          nhầm lẫn giữa cách khóa van và mở van.
        </p>
        <p>
          Trong thực tế, cách tốt nhất là khi sử dụng xong Bạn nên khóa bình gas
          lại và khi sử dụng thì hãy mở ra. Mỗi khi mở bình gas ra để sử dụng
          thì phải nhấn nút Reset (cầu chì) trên van gas thì bếp mới hoạt động.
        </p>
        <br />
        <br />
        <h3>Cách tháo lắp bình gas van chụp:</h3>
        <br />
        <h4 className="l-20">Tháo:</h4>
        <div className="l-30">
          <p>
            {" "}
            Đầu tiên bạn nhấn chốt bình gas từ trái qua phải sau đó quay một nửa
            vòng 90 độ theo chiều kim đồng hồ. Sau khi đã quay một vòng 90 độ
            dừng lại nhả nút van gas. Và tiến hành xoay một vòng 90 độ theo
            chiếu kim đồng hồ và tiến hành nhấc van bình ra khỏi bình gas. Như
            vậy đã hoàn thành cách tháo bình gas van chụp.
          </p>
          <br />
        </div>
        <h4 className="l-20">Lắp:</h4>
        <div className="l-30">
          <p>Bạn chỉ cần thực hiện ngược lại các bước trên là được.</p>
        </div>
      </div>
      <br />
      <br />
      <p>
        Trên đây là những kinh nghiệm và hướng dẫn chi tiết cách tháo lắp bình
        gas. Hy vọng qua bài viết này Quý khách hàng có thể biết cách sử dụng
        gas an toàn và tiết kiệm nhất cho gia đình mình.
      </p>
    </div>
  );
};

export default ThaoLapBinhGas;
