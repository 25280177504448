import React from "react";

const YeuCauAnToan = () => {
  return (
    <div id="yeucauantoan">
      <h2>Yêu cầu an toàn đối với khí hoá lỏng</h2>
      <br />
      <div className="l-10">
        <p>
          Khí hoá lỏng (LPG) thông thường là propan hoặc butan, hoặc cũng có thể
          là hỗn hợp cả hai chất khí đó. LPG thường được bán dưới một số loại
          nhãn hiệu thương mại khác nhau, được sử dụng rất rộng rãi trên các
          công trường và là nhân tố thường xuyên gây ra tai nạn. Chất khí lỏng
          khi rò rỉ ra khỏi bình chứa ngay lập tức hoá hơi và do khối lượng
          riêng năng hơn không khí, chúng bay là là trên mặt đất rồi tụ lại
          trong các ống đẫn, hố đào và những chỗ thấp. Vì chỉ cần chiếm 2% hàm
          lượng không khí là chứng đã có thể tạo thành hợp chất có thể gây cháy
          nên nếu bị ỉưu cữu lại trong những chỏ không gian hạn hẹp, khí rò rì
          sẽ trở thành hiểm hoạ gây nổ vào bất cứ lúc nào. Vì vậy, bất cứ khi
          nào sử dụng những phương pháp gia công bằng LPG cũng cần phải có bố
          trí thông gió tốt.
        </p>
        <br />
        <h3>Lưu chứa khí hóa lỏng</h3>
        <ul className="l-35">
          <li>
            Muốn lưu chứa khí hoá lỏng phải tuân theo các tiêu chuẩn sau đây:
          </li>
          <li>
            Nơi lưu chứa các bình LPG trên công trường phải thoáng khí, trên nền
            đất phẳng và có hàng rào cao tối thiểu là 2m bao quanh; cần có đủ
            mái che để các bình khí này không tiếp xúc trực tiếp với nhiệt độ
            cao.
          </li>
          <li>
            Các bình chứa khí phải được để cách hàng rào l,5m và cách địa phận
            công trường ít nhất là 3m.
          </li>
          <li>
            Không để các bình LPG ở vị trí thấp hơn mặt nền và gần những bình
            oxy hay vật liệu độc hoặc có tính ăn mòn như amoniắc hay khí clo.
          </li>
          <li>Phải có biển báo “LPG” – dễ cháy, cấm hút thuốc và cấm lửa.</li>
          <li>
            Bình chứa dù còn hay hết, cũng phải dựng đúng lên với van an toàn ở
            vị trí trên cùng.
          </li>
          <li>
            Van của bình đã hết khí phải để ở vị trí đóng đề phòng không khí có
            thể xâm nhập vào bình tạo ra những hợp chất dễ cháy nổ.
          </li>
          <li>Phải có sẵn một loại bột chữa cháy ở trong kho.</li>
        </ul>
        <br />
        <h3>Sử dụng bình khí hóa lỏng:</h3>
        <p className="l-20">
          <strong>
            Khi sử dụng các bình khí hoá lỏng, cần tính đến những yếu tố sau:
          </strong>
        </p>
        <ul className="l-35">
          <li>
            Một van hư hỏng hoặc bị rò rỉ có thể gây hậu quả nghiêm trọng.
          </li>
          <li>
            Các bình khí không sử dụng phải có nắp đậy bảo vệ các van và bộ điều
            chỉnh.
          </li>
          <li>
            Chuyên chở bình chứa LPG bằng các xe đẩy, xe trượt hay các đệm đỡ;
            tuyệt đối không nâng bình bằng cách nắm vào các mối lắp van.
          </li>
          <li>
            Trước khi sử dụng bình chứa, đảm bảo các mối lắp ghép phải kín khí
            bằng cách dùng nước xà phòng và bàn chải để thử.
          </li>
          <li>
            Nếu phát hiện rò rỉ, ngay lập tức chuyển bình khí tới nơi thoáng gió
            và thông báo ngay cho đốc công.
          </li>
          <li>Bình chứa sử dụng cho gia công nhiệt không được để trong nhà.</li>
          <li>
            Nếu khi thắp một mỏ hàn mà diêm hay nến tắt trước khi mỏ hàn phát
            lửa, hãy khoá van lại trước khi châm que diêm hay cây nến khác.
          </li>
        </ul>
        <br />
        <h3>Những điểm cần nhớ</h3>
        <ul className="l-35">
          <li>Khoá van lại khi không sử dụng bình chứa.</li>
          <li>
            Để bình chứa LPG gần nguồn lửa có thể làm cho khí nóng trong bình bị
            đun lên và bình bị nổ, hậu quả sẽ rất nguy hiểm.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default YeuCauAnToan;
