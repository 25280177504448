import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Content from "../components/Content";
import Footer from "../components/Footer";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Route path="*" component={Banner} />
      <Content />
      <Footer />
    </Router>
  );
};

export default App;
