import React, { useState, useEffect } from "react";

import "./Banner.css";

const Banner = ({ location }) => {
  const [image, setImage] = useState("");
  const [slogan, setSlogan] = useState("");

  useEffect(() => {
    if (location.pathname === "/") {
      setImage("trang-chu.jpg");
      setSlogan("ENERGY SOLUTIONS");
    } else if (/sanpham/.test(location.pathname)) {
      setImage("san-pham.jpg");
      setSlogan("PRODUCTIONS");
    } else if (/dichvu/.test(location.pathname)) {
      setImage("dich-vu.jpg");
      setSlogan("SERVICE");
    } else if (/tailieu/.test(location.pathname)) {
      setImage("tai-lieu.jpg");
      setSlogan("DOCUMENTS");
    }
  }, [location.pathname]);

  return (
    <div id="banner" className="row">
      <img className="col-12" src={"./images/banner/" + image} alt="bg" />
      <div className="center-mid">
        <p className="text-center">{slogan}</p>
      </div>
    </div>
  );
};

export default Banner;
