import React from "react";

const NangLucThietBi = () => {
  const data = [
    { stt: "STT", type: "LOẠI THIẾT BỊ", unit: "ĐƠN VỊ", count: "SỐ LƯỢNG" },
    { type: "Máy hàn một chiều", unit: "Cái", count: "03" },
    { type: "Máy hàn tích – DC", unit: "Cái", count: "02" },
    { type: "Máy hàn xoay chiều - DC", unit: "Cái", count: "02" },
    { type: "Máy hàn điện cực – Dùng hàn ống PE", unit: "Cái", count: "01" },
    { type: "Mỏ lết vặn ống", unit: "Cái", count: "04" },
    { type: "Bộ ren ta rô", unit: "Bộ", count: "01" },
    { type: "Máy kiểm tra rò rỉ khí Gas (LPG/CNG)", unit: "Cái", count: "06" },
    { type: "Máy khoan", unit: "Cái", count: "03" },
    { type: "Thiết bị cắt ông mềm innox", unit: "Cái", count: "06" },
    { type: "Máy đục bê tông", unit: "Cái", count: "02" },
    { type: "Bộ hàn cắt bằng hơi", unit: "Bộ", count: "01" },
    { type: "Máy làm ren ống", unit: "Cái", count: "01" },
    { type: "Máy cắt sắt", unit: "Cái", count: "01" },
    { type: "Máy mài sắt", unit: "Cái", count: "02" },
    { type: "Máy cắt ông nhựa PE", unit: "Cái", count: "01" },
    { type: "Máy siết bulông", unit: "Cái", count: "03" },
    { type: "Máy nén khí P=9Kg/cm2", unit: "Cái", count: "01" },
    { type: "Máy bơm thử thủy lực", unit: "Cái", count: "01" },
    { type: "Máy bơm nước cao áp", unit: "Cái", count: "02" },
    { type: "Xe nâng hạ 1,5 tấn GCPower – China", unit: "Xe", count: "01" },
    { type: "Máy phát điện AC – 3HP", unit: "Cái", count: "01" },
  ];

  return (
    <table className="col-12">
      {data.map((item, i) => (
        <>
          <tr>
            <td className="col-1 text-center">{i !== 0 ? i : item.stt}</td>
            <td className="col-7">{item.type}</td>
            <td className="col-2 text-center">{item.unit}</td>
            <td className="col-2 text-center">{item.count}</td>
          </tr>
          <hr />
        </>
      ))}
    </table>
  );
};

export default NangLucThietBi;
