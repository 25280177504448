import React from "react";

const XuLyRoRiGas = () => {
  return (
    <div id="xulyrorigas">
      <h2>Xử lý rò rỉ khí gas</h2>
      <br />
      <div className="l-10">
        <h3>Phát hiện rò rỉ gas</h3>
        <p className="l-20">
          Một ngày nào đấy, bạn bước vào bếp và ngửi thấy mùi rất… lạ. Rất có
          thể là gas đang rò rỉ. Nhưng “mùi lạ” chỉ là một trong những dấu hiệu
          nhận biết gas bị rò rỉ thôi. Hãy xem những dấu hiệu còn lại giúp nhận
          biết dễ dàng sự cố này nhé!
        </p>
        <br />
        <p className="l-20">
          <strong>Đầu tiên là mùi nồng đặc trưng</strong>
        </p>
        <p className="l-30">
          Mặc dù gas không màu không mùi, nhưng để đảm bảo an toàn trong quá
          trình sử dụng và giúp người dùng phát hiện nếu có xảy ra rò rỉ, các
          nhà sản xuất đã thêm vào chất mercaptan có mùi đặc trưng hơi giống như
          mùi trứng thối. Nếu bạn ngửi thấy mùi này, hãy nhanh chóng tắt bếp,
          khóa van bình gas, mở cửa, rồi ra khỏi khu vực có mùi gas và gọi ngay
          cho cửa hàng gas để được giúp đỡ
        </p>
        <br />
        <p className="l-20">
          <strong>Ngoài ra,</strong>
          <strong>một mẹo nhỏ khác là xác côn trùng chết quanh bình ga</strong>
        </p>
        <p className="l-30">
          Thêm một dấu hiệu khác để giúp các bạn nhận biết gas rò rỉ đó là xác
          côn trùng chết xung quanh bình gas. Khí gas tự nhiên không độc nhưng
          nó sẽ chiếm chỗ oxy trong không khí. Côn trùng, đặc biệt là ruồi, bị
          thu hút bởi mùi trứng thối trong khí gas và sẽ bị ngộp thở. Nếu nhận
          thấy điều bất thường này thì rất có thể bình gas nhà bạn đang bị rỏ
          rỉ. Bạn nên kiểm tra lại một lượt từ vỏ bình đến van gas và dây dẫn để
          có thể phát hiện và xử lý kịp thời.
        </p>
        <br />
        <h3>Cách xử lý</h3>
        <p className="l-20">
          Nhận biết những dấu hiệu rò rỉ gas và trang bị những kỹ năng bảo vệ an
          toàn là cách để chúng ta luôn sống chung vui vẻ với bếp gas đến suốt
          đời.
        </p>
        <p className="l-20">
          Khi phát hiện ra trong khuôn viên bếp có mùi nồng đặc trưng, hay gặp
          các sự cố về nguồn lửa, hay bếp không bắt lửa, bạn cần xử lý nhanh gọn
          để bảo vệ an toàn cho bản thân và mọi thành viên trong gia đình qua
          các bước sau:
        </p>
        <br />
        <ul className="l-35">
          <li>
            Khóa ngay van gas và tắt bếp: Cắt đứt nguồn khí gas đang rò rỉ là
            điều đầu tiên bạn cần làm.
          </li>
          <li>
            Tuyệt đối không chạm vào bất cứ nguồn nào có thể gây ra tia lửa: Chỉ
            một tia lửa nhỏ thôi cũng đủ mọi chuyện thành “chuyện đã rồi”, do đó
            bạn không nên bật tắt cầu dao, công tắc điện, quạt điện và không sử
            dụng điện thoại trong bếp.
          </li>
          <li>Mở ngay cửa sổ khí gas nhanh chóng thoát ra khỏi nhà</li>
          <li>
            Di chuyển ra khu vực không có khí gas và liên lạc với nhân viên
            Totalgaz để được hỗ trợ
          </li>
        </ul>
      </div>
    </div>
  );
};

export default XuLyRoRiGas;
