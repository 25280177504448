import React from "react";

const LinhVucKinhDoanh = () => {
  return (
    <div>
      <div className="l-10">
        <h4>1. Dịch vụ Công nghệ và Kỹ thuật</h4>
        <ul className="l-35">
          <li>
            Tư vấn thiết kế, lắp đặt, đầu tư và vận hành hệ thống công nghệ,
            thiết bị cung cấp khí gas (LPG/CNG/LNG) phục vụ công nghiệp và dân
            dụng;
          </li>
          <li>
            Tư vấn thiết kế, lắp đặt, đầu tư và vận hành công nghệ, thiết bị Hệ
            thống PCCC cho các công trình chung cư, cao ốc văn phòng, khách sạn,
            kho chứa và nhà xưởng;
          </li>
          <li>
            Kinh doanh dịch vụ lắp hệ thống cấp điện chiếu sáng công nghiệp và
            công cộng, hệ thống cung cấp khí nén công nghiệp;
          </li>
          <li>
            Tư vấn đầu tư dự án, dấu thầu và chuyển giao công nghệ, bảo dưỡng,
            sửa chữa và lắp đặt các thiết bị khác phục vụ sản xuất kinh doanh
            gas (LPG/CNG/LNG) như: Trạm nạp LPG cho Autogas, Trạm mẹ CNG (CNG
            Mother Station), Trạm con CNG (CNG Daughter Station), Hệ thống khí
            đốt công nghiệp;
          </li>
          <li>
            Tư vấn thiết kế, lắp đặt, vận hành chạy thử và chuyển giao công nghệ
            các công trình khí như: Kho chứa khí, Trạm chiết nạp khí, Hệ thống
            gas trung tâm, Hệ thống cung cấp khí nén công nghiệp, Hệ thống cung
            cấp khí O2, N2; Hệ thống Biogas, Xử lý chất thải công nghiệp và môi
            trường;
          </li>
          <li>
            Cung cấp vật tư đường Ống ximăng; Ống thép; Ống nhựa composite; Ống
            Cao su; Ống bọc nhựa và các phụ kiện kết nối Elbow, Tee, Reducer ...
          </li>
          <li>
            Cung cấp các loại van công nghiệp cho lĩnh vực Dầu – Khí – Nước như:
            Plug Valve; Ball Valve; Gate Valve; Globe Valve; Buterfly Valve;
            Check Valve; Follow Meter; Power Rewind Liquid Reel; Hose Reel; Máy
            hóa hơi;
          </li>
          <li>
            Cung cấp dịch vụ bảo trì bảo dưỡng hệ thống gas trung tâm, gas công
            nghiệp, công trình Dầu – Khí, Hóa chất.
          </li>
        </ul>
        <br />
      </div>
      <div className="l-10">
        <h4>2. Mua bán nhiên liệu khí - Gas LPG, CNG & LNG</h4>
        <ul className="l-35">
          <li>
            Mua bán khi LPG cho trung tâm hội nghị tiệc cưới, nhà hàng, khách
            sạn, Chung cư, Trung tâm thương mại và Trường học.
          </li>
          <li>
            Bán buôn nhiên liệu rắn, lỏng, khí- LPG, CNG và LNG cho công nghiệp
            và dân dụng.
          </li>
          <li>Bán buôn xăng dầu cho các hộ tiêu thụ công nghiệp</li>
        </ul>
        <br />
      </div>
      <div className="l-10">
        <h4>3. Mua bán vật tư và Thiết bị</h4>
        <ul className="l-35">
          <li>
            Cung cấp vật tư, phụ tùng và máy móc thiết bị Dầu – Khí, Hóa chất và
            Công nghệ xử lý môi trường;
          </li>
          <li>
            Xuất nhập khẩu và phân phối hàng hóa, vật tư và thiết bị công nghiệp
            như van, đường ống, máy phát điện, máy bơm và máy nén khí;
          </li>
          <li>
            Sản xuất máy bơm, máy nén, ống dẫn khí và van khác; Sản xuất máy
            thông dụng khác: Sản xuất máy hoá lỏng khí và gas; Sản xuất máy cung
            cấp gas;
          </li>
          <li>
            Sản xuất khí đốt, phân phối nhiên liệu khí bằng đường ống (Không sản
            xuất tại trụ sở Công ty).
          </li>
        </ul>
      </div>
    </div>
  );
};

export default LinhVucKinhDoanh;
