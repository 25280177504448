import React from "react";

const Catalog = () => {
  return (
    <div>
      <img
        className="col-6 image-catalog"
        src="./images/production/pv-tt.png"
        alt="img"
      />
      <img
        className="col-6 image-catalog"
        src="./images/production/total-about.png"
        alt="img"
      />
      <img
        className="col-6 image-catalog"
        src="./images/production/total-cn-dv.png"
        alt="img"
      />
      <img
        className="col-6 image-catalog"
        src="./images/production/total-gd-cn.png"
        alt="img"
      />
    </div>
  );
};

export default Catalog;
