import React from "react";

import "./SanPham.css";
import Total from "./Total";
import PVGas from "./PVGas";
import ThienAn from "./ThienAn";

const SanPham = () => {
  return (
    <>
      <h3 className="supplier-name">Total Gas</h3>
      <Total />
      <h3 className="supplier-name">Petrol Việt Nam</h3>
      <PVGas />
      <h3 className="supplier-name">Thiên An</h3>
      <ThienAn />
    </>
  );
};

export default SanPham;
